<script setup lang="ts">
import type { NavItem } from '@nuxt/content'
import { type SearchItems, titleCase } from './util'

const { page } = useContent()

const isIndex = computed(() => page.value._path === '/')

const search: Ref<SearchItems> = inject('search', ref({} as SearchItems))

const links = computed(() => {
  const pages = Object.values(search.value)
  const parts = page.value._path.split('/').slice(0, -1)

  return parts.map((part: string, index: number) => {
    const path = parts.slice(0, index + 1).join('/') || '/'
    const page = pages.find((p: NavItem) => p._path === path)
    return { label: page?.title || part, path: page?._path }
  })
})
</script>

<template>
  <nav v-if="!isIndex" class="breadcrumbs">
    <div v-for="{ path, label } in links" :key="path" class="breadcrumb title-font">
      <NuxtLink v-if="path" :key="path + label" :to="path" no-prefetch class="link">
        {{ titleCase(label) }}
      </NuxtLink>
      <span v-else :key="'missing ' + path + label" class="no-link">
        {{ titleCase(label) }}
      </span>
      <Icon name="estia:triquetra" />
    </div>
  </nav>
</template>

<style scoped lang="postcss">
.breadcrumbs {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin-bottom: 2rem;
  min-height: 1.5rem;

  @media screen and (max-width: 600px) {
    margin-bottom: 1rem;
  }

  .breadcrumb {
    display: flex;
    align-items: center;

    .icon {
      margin-left: 1rem;
      margin-right: 0.75rem;
    }

    &:last-of-type .icon {
      display: none;
    }
  }
}
</style>
